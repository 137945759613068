import { Link } from 'gatsby'
import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 20px;
`

export const ProductHeader = styled.div`
  position: relative;
  width: 100%;
  padding-top: 40px;
  margin-bottom: 40px;
  aspect-ratio: 16/9;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`

export const ProductHeaderTop = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    height: auto;
    gap: 12px;
    position: relative;
    margin-bottom: 20px;
  }
`

export const ProductHeaderTopInner = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const ProductHeaderContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
`

export const ProductHeaderBottom = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  height: 78px;
`

export const MainImage = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  aspect-ratio: 16/9;

  img {
    width: 100%;
    height: auto;
  }
`

export const WishlistButton = styled.button<{ $isWishlisted: boolean }>`
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ $isWishlisted }) => ($isWishlisted ? '#000' : '#666')};
`

export const ProductTitle = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 400;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    color: #16283e;
    font-family: var(--font-family-sans-serif);
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`

export const ProductTitleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    align-self: flex-end;
  }
`

export const InquireButton = styled.button`
  background: transparent;
  color: white;
  padding: 12px 40px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: #16283e;
  text-decoration: underline;
  text-underline-offset: 8px;

  &:hover {
  }
`

export const ProductContent = styled(Container)`
  @media (max-width: 768px) {
    padding-top: 20px;
  }
`

export const MadeToOrder = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  font-family: var(--font-family-sans-serif);
  color: #16283e;
`

export const ProductDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 40px;
  max-width: 800px;
  font-family: var(--font-family-sans);
  color: #000;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 22px;
    margin-bottom: 10px;

    &:before {
      content: ' — ';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
    }
  }
`

export const ProductFeatures = styled.div`
  margin-bottom: 60px;
`

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
`

export const FeatureItem = styled.li`
  font-size: 14px;
  line-height: 2;
  color: #333;
`

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
`

export const DetailImage = styled.div`
  /* &:first-child {
    grid-column: 1 / -1;
  } */

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

export const BespokeLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 12px;

    svg {
      width: 36px;
    }
  }

  span {
    color: #16283e;
    font-family: var(--font-family-sans-serif);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 7px;

    @media (max-width: 768px) {
      font-size: 8px;
    }
  }
`

export const BespokeMenusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const BespokeMenu = styled(Link)`
  color: #16283e;

  font-family: var(--font-family-sans-serif);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 7.99px;
  letter-spacing: 0.976px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &[data-active='true'] {
    text-decoration: underline;
    text-underline-offset: 8px;
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
  }
`

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Breadcrumb = styled(Link)`
  color: #b0b0b0;
  font-family: var(--font-family-sans-serif);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 8px;

  &[data-active='true'] {
    cursor: default;
    pointer-events: none;
  }

  &:hover:not([data-active='true']) {
    span {
      color: #16283e;
      text-decoration: underline;
      text-underline-offset: 8px;
    }
  }

  &:after {
    content: ' /';
    color: #b0b0b0;
    font-family: var(--font-family-sans-serif);
    font-size: 13px;
    font-style: normal;
    margin-left: 6px;
    margin-right: 6px;
    font-weight: 400;
    line-height: normal;
  }
`
