import React, { useCallback, useMemo } from 'react'
import { BespokeMenu, BespokeMenusWrapper, Breadcrumb, BreadcrumbsWrapper } from './styles'
import { graphql, useStaticQuery } from 'gatsby'
import { useProductDetail } from '../Provider'
import { ProductItemComponentQuery } from '~/@types/models'

interface Collection {
  node: {
    handle: string
    title: string
    productsCount: number
    products: {
      title: string
      handle: string
      collections: {
        handle: string
      }[]
    }[]
  }
}

const query = graphql`
  query AllBespokeCollectionsHeaders {
    allShopifyCollection(filter: { templateKey: { regex: "/^bespoke/i" } }) {
      edges {
        node {
          handle
          title
          productsCount
          products {
            title(raw: false)
            handle
            collections {
              handle
            }
          }
        }
      }
    }
  }
`

export const getRoute = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const shopRoutes = ['collections', 'products']
  const pathname = window.location.pathname

  const route = shopRoutes.find(route => pathname.includes(route))

  const rootRoute = pathname.split(`/${route}/`)[0].split('/').pop()

  return rootRoute
}

export const useDynamicCollectionMenus = () => {
  const data = useStaticQuery<{ allShopifyCollection: { edges: Collection[] } }>(query)
  const route = getRoute()
  const collections = data.allShopifyCollection.edges

  const isActive = useCallback(
    (handle?: string) => {
      if (typeof window === 'undefined' || !route) {
        return false
      }

      const pathname = window.location.pathname
      const productHandle = pathname.includes(`/${route}/products/`)
        ? pathname.split(`/${route}/products/`).pop()
        : null

      if (productHandle) {
        const collection = collections.find(edge =>
          edge.node.products.some(product => product.handle === productHandle)
        )
        return collection?.node.handle === handle
      }

      const collectionHandle = pathname.includes(`/${route}/collections/`)
        ? pathname.split(`/${route}/collections/`).pop()
        : null

      return collectionHandle === handle
    },
    [collections, route]
  )

  const home = useMemo(() => {
    return {
      label: 'HOME',
      link: `/${route}`,
      handle: null,
      isActive: false,
      productsCount: 0,
    }
  }, [route])

  const menus = useMemo(() => {
    return route
      ? collections.map(collection => ({
          label: collection.node.title.split('|')[0].trim(),
          link: `/${route}/collections/${collection.node.handle}`,
          handle: collection.node.handle,
          isActive: isActive(collection.node.handle),
          productsCount: collection.node.productsCount,
        }))
      : []
  }, [route, collections, isActive])

  const breadcrumbs = menus?.length ? [home, ...menus.filter(menu => menu.isActive)] : []

  return { menus, breadcrumbs, isActive }
}

export const DynamicBreadcrumbs = () => {
  const { breadcrumbs } = useDynamicCollectionMenus()

  if (!breadcrumbs.length) {
    return null
  }

  return (
    <BreadcrumbsWrapper>
      {breadcrumbs.map(breadcrumb => (
        <Breadcrumb
          key={breadcrumb.label}
          to={breadcrumb.link || ''}
          data-active={breadcrumb.isActive}
        >
          <span>{breadcrumb.label}</span>
        </Breadcrumb>
      ))}
    </BreadcrumbsWrapper>
  )
}

export const BespokeMenus = () => {
  const { menus, isActive } = useDynamicCollectionMenus()

  return (
    <BespokeMenusWrapper>
      {menus.map(menu => (
        <BespokeMenu data-active={isActive(menu.handle)} key={menu.label} to={menu.link}>
          {menu.label}
        </BespokeMenu>
      ))}
    </BespokeMenusWrapper>
  )
}
